/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { useState, useEffect } from "react";
import {
	Dialog,
	DialogContent,
	DialogFooter,
	DialogHeader,
	DialogTitle
} from "@/components/ui/dialog";
import {
	Select,
	SelectContent,
	SelectGroup,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/components/ui/select";
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "@/components/ui/collapsible";
import { Button } from "@/components/ui/button";
import * as Checkbox from '@radix-ui/react-checkbox';
import { cn } from "@/lib/utils";
import { ChevronsUpDown, CheckIcon } from "lucide-react";

import { type OpenStates } from "@/types/AccordionOpenStates";


type OnOffView = 'all' | 'on' | 'off';

type Group = {
	department: string | number;
	positions: string[] | number[];
}

type FiltersProps = {
	show: boolean;
	onHide: () => void;
	defaultOnOffView: OnOffView;
	selectedDepartmentsAndPositions: Group[];
	setSelectedDepartmentsAndPositions: React.Dispatch<React.SetStateAction<Group[]>>;
    data: undefined | null | {
        id: number;
        name: string;
        positions: {
            id: number;
            name: string;
        }[];
    }[]
}
const Filters = ({
	show,
	onHide,
	defaultOnOffView,
	selectedDepartmentsAndPositions,
	setSelectedDepartmentsAndPositions,
    data
}: FiltersProps) => {
	const [onOffView, setOnOffView] = useState<OnOffView>(defaultOnOffView);
	const [openStates, setOpenStates] = useState<OpenStates>({});

	const [selectedFilters, setSelectedFilters] = useState<Group[]>([]);

	// const data = [
	// 	{
	// 		id: 1,
	// 		name: "Deck",
	// 		positions: [
	// 			{
	// 				id: 1,
	// 				name: "Captain",
	// 			},
	// 			{
	// 				id: 2,
	// 				name: "C/O",
	// 			}
	// 		]
	// 	},
	// 	{
	// 		id: 2,
	// 		name: "Engineer",
	// 		positions: [
	// 			{
	// 				id: 4,
	// 				name: "C/E",
	// 			}
	// 		]
	// 	},
	// 	{
	// 		id: 3,
	// 		name: "Purser",
	// 		positions: [
	// 			{
	// 				id: 5,
	// 				name: "Purser",
	// 			}
	// 		]
	// 	},
	// 	{
	// 		id: 4,
	// 		name: "Interior",
	// 		positions: [
	// 			{
	// 				id: 6,
	// 				name: "C/S",
	// 			}
	// 		]
	// 	},
	// ]

	const onDepartmentChangeHandler = (departmentId: number) => {
		const selectedFilterIndex = selectedFilters.findIndex( filter => filter.department === departmentId);
		if(selectedFilterIndex === -1) {
			setSelectedFilters([...selectedFilters, {department: departmentId, positions: data.find( department => department.id === departmentId)?.positions.map( position => position.id) || []}]);
		} else {
			const updatedFilters = selectedFilters.filter( filter => filter.department !== departmentId);
			setSelectedFilters(updatedFilters);
		}
	}

	const getDepartmentValue = (departmentId: number) => {
		return selectedFilters.findIndex( filter => filter.department === departmentId) !== -1;
	}

	const onPositionChangeHandler = (departmentId: number, positionId: number) => {
		const selectedFilterIndex = selectedFilters.findIndex( filter => filter.department === departmentId);
		if(selectedFilterIndex === -1) {
			setSelectedFilters([...selectedFilters, {department: departmentId, positions: [positionId]}]);
		} else {
			const updatedFilters = selectedFilters.map( filter => {
				if(filter.department === departmentId) {
					if(filter.positions.includes(positionId)) {
						return {
							...filter,
							positions: filter.positions.filter( position => position !== positionId)
						}
					} else {
						return {
							...filter,
							positions: [...filter.positions, positionId]
						}
					}
				}
				return filter;
			}).filter( filter => filter.positions.length > 0);
			setSelectedFilters(updatedFilters);
		}
	}

	const getPositionValue = (departmentId: number, positionId: number) => {
		const department = selectedFilters.find( filter => filter.department === departmentId);

		if(department) {
			return department.positions.includes(positionId);
		}
		return false;
	}

	const onCancelHandler = () => {
		setSelectedFilters(selectedDepartmentsAndPositions);
		onHide();
	}

	const toggleOpenState = (sectionId: number) => {
		setOpenStates((prevStates) => ({
			...prevStates,
			[sectionId]: !prevStates[sectionId],
		}));
	};

	const onOffViewHandler = (value: OnOffView) => {
		setOnOffView(value);
	}

	const onApplyHandler = () => {
		setSelectedDepartmentsAndPositions(selectedFilters);
		onHide();
	}

	useEffect(() => {
		if(show) {
			setSelectedFilters(selectedDepartmentsAndPositions);
		}
	}, [show, selectedDepartmentsAndPositions]);

	return (
		<Dialog open={show} onOpenChange={onHide} defaultOpen={false}>
			<DialogContent>
				<DialogHeader>
					<DialogTitle></DialogTitle>
				</DialogHeader>

				<div className="overflow-y-auto">
					
						<div className="grid grid-cols-1 items-center gap-3 sm:grid-cols-2 p-1">
							<div className="flex items-center justify-start gap-2">
								<div className="whitespace-nowrap">On/Off View</div>
							</div>
							<div className="">
								<Select onValueChange={onOffViewHandler} defaultValue={onOffView} disabled={false}>
									<SelectTrigger>
										<SelectValue />
									</SelectTrigger>
									<SelectContent>
										<SelectGroup>
											{ [
												{ label: 'Show All Crew', value: 'all' },
												{ label: 'Crew Signed On', value: 'on' },
												{ label: 'Crew Signed Off', value: 'off' }
											].map(({label, value}) => (
												<SelectItem key={label} value={value}>{label}</SelectItem>
											))}
										</SelectGroup>
									</SelectContent>
								</Select>
							</div>
						</div>

						<hr className="my-1" />

						<div className="flex items-center justify-start p-1">
							<div className="whitespace-nowrap">Selected department(s) and position(s)</div>
						</div>
						{data?.map((department) => {
								return (
									<Collapsible
										key={department.id}
										open={openStates[department.id] || false}
										onOpenChange={() => toggleOpenState(department.id)}
										className="p-1 pb-0"
									>
										<div className="flex items-center justify-between mb-1">
											<CollapsibleTrigger asChild>
												<Button
													variant="ghost"
													size="sm"
													className={cn("flex w-full items-center justify-between border bg-slate-50")}
												>
													<h4 className="text-sm font-semibold">{department.name}</h4>

													<div className="flex items-center">
														
														<div onClick={(e) => {e.stopPropagation()}}>
															<Checkbox.Root className="w-6 h-6 flex items-center justify-center whitespace-nowrap rounded-md text-sm ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border border-input bg-background hover:bg-accent hover:text-accent-foreground justify-start text-left font-normal text-muted-foreground"
																onCheckedChange={() => onDepartmentChangeHandler(department.id)}
																checked={getDepartmentValue(department.id)}
															>
																<Checkbox.Indicator className="CheckboxIndicator">
																	<CheckIcon />
																</Checkbox.Indicator>
															</Checkbox.Root>
														</div>
														
														<ChevronsUpDown className="h-4 w-4" />
														<span className="sr-only">Toggle</span>
													</div>
												</Button>
											</CollapsibleTrigger>
										</div>
										<CollapsibleContent className="space-y-1 ps-3">
											{(!department?.positions || department?.positions?.length < 1) && (
												<div className="text-center text-sm">
													No positions available for selected department
												</div>
											)}

											{department?.positions?.map((position) => {
												return (
													<div key={`contract-${position.id}`} className="relative flex flex-col ">
														
														<div className="border rounded-md p-1 px-2 flex items-center justify-between">
															<div>{position.name}</div>

															<Checkbox.Root className="w-6 h-6 flex items-center justify-center whitespace-nowrap rounded-md text-sm ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border border-input bg-background hover:bg-accent hover:text-accent-foreground justify-start text-left font-normal text-muted-foreground"
																	onCheckedChange={() => onPositionChangeHandler(department.id, position.id)}
																	checked={getPositionValue(department.id, position.id)}
																>
																	<Checkbox.Indicator className="CheckboxIndicator">
																		<CheckIcon />
																	</Checkbox.Indicator>
															</Checkbox.Root>
														</div>

														
													</div>
												);
											})}
										</CollapsibleContent>
									</Collapsible>
								);
							},
						)}
					<hr className="mt-3" />
				</div>

				<DialogFooter>
					<div className="flex w-full justify-end gap-2">
						<Button type="button" variant="secondary" onClick={onCancelHandler}>
							Cancel
						</Button>
						<Button type="button" onClick={onApplyHandler}>
							Apply
						</Button>
					</div>
				</DialogFooter>

			</DialogContent>
		</Dialog>
	)
}

export {
    Filters
};
