import { type CellContext, type ColumnDef } from "@tanstack/react-table";
import { Link } from "react-router-dom";

import { EditVesselDialog } from "@/components/dialogs/EditVesselDialog";
import { RemoveVesselDialog } from "@/components/dialogs/RemoveVesselDialog";
import { Button } from "@/components/ui/button";
import { type Vessel } from "@/types/Vessel";
import { Calendar, CreditCard, GitFork, Ship, UserCog, Users } from "lucide-react";

export const columns: ColumnDef<Vessel>[] = [
	{
		accessorKey: "name",
		header: "Name",
		cell: ({ row }) => <div className="capitalize">{row.getValue("name")}</div>,
	},
	{
		accessorKey: "fleet",
		accessorFn: (row) => row?.fleet?.name,
		header: "Fleet",
		cell: ({ row }) => {
			const fleet = row.original?.fleet;

			return <div>{fleet ? fleet?.name : "-"}</div>;
		},
	},
	{
		accessorKey: "imo",
		header: "IMO",
		cell: ({ row }) => <div>{row.getValue("imo")}</div>,
	},
	{
		accessorKey: "isActive",
		header: () => <div>Is Active?</div>,
		cell: ({ row }) => {
			const hasSubscription = row.original?.subscription;

			if (!hasSubscription) return "-";

			const isActive = row.original?.subscription?.isActive;

			return <div>{isActive ? "Yes" : "No"}</div>;
		},
		meta: {
			getCellContext: (context: CellContext<Vessel, unknown>) => {
				const hasSubscription = context.row.original?.subscription;
				const isActive = context.row.original?.subscription?.isActive;

				if (hasSubscription && isActive) return;

				return {
					className: "bg-red-300 group-hover:bg-red-400",
				};
			},
		},
	},
	{
		accessorKey: "subscriptionType",
		header: () => <div>Subscription Type</div>,
		cell: ({ row }) => {
			const hasSubscription = row.original?.subscription;

			if (!hasSubscription) return <div>-</div>;

			const type = row.original?.subscription?.tier?.type;

			return <div>{type}</div>;
		},
		meta: {
			getCellContext: (context: CellContext<Vessel, unknown>) => {
				const hasSubscription = context.row.original?.subscription;

				if (hasSubscription) return;

				return {
					className: "bg-red-300 group-hover:bg-red-400",
				};
			},
		},
	},
	{
		accessorKey: "paymentUntil",
		header: () => <div>Payment until</div>,
		cell: ({ row }) => {
			const hasSubscription = row.original?.subscription;

			if (!hasSubscription) return <div>-</div>;

			const paymentUntil = row.original?.subscription?.paymentUntil;

			return <div>{paymentUntil}</div>;
		},
		meta: {
			getCellContext: (context: CellContext<Vessel, unknown>) => {
				const hasSubscription = context.row.original?.subscription;

				if (hasSubscription) return;

				return {
					className: "bg-red-300 group-hover:bg-red-400",
				};
			},
		},
	},
	{
		accessorKey: "usersCount",
		header: () => <div>Users Count</div>,
		cell: ({ row }) => {
			const hasSubscription = row.original?.subscription;

			if (!hasSubscription) return <div>-</div>;

			const usersLimit = row.original?.subscription?.tier?.usersLimit;

			return (
				<div>
					{row.getValue("usersCount")}/{usersLimit}
				</div>
			);
		},
		meta: {
			getCellContext: (context: CellContext<Vessel, unknown>) => {
				const hasSubscription = context.row.original?.subscription;

				if (hasSubscription) return;

				return {
					className: "bg-red-300 group-hover:bg-red-400",
				};
			},
		},
	},
	{
		id: "actions",
		header: () => <div>Actions</div>,
		cell: ({ row }) => {
			const vesselId = row.original.id;

			return (
				<div className="flex justify-center gap-2">
					<EditVesselDialog vesselId={vesselId} vessel={{ ...row.original }} />
					<Button variant="outline" size="icon" asChild style={{ background: "#4CAF50" }}>
						<Link to={`/vessel/${vesselId}/branding`}>
							<Ship color="#fff" />
						</Link>
					</Button>
					<Button variant="outline" size="icon" asChild style={{ background: "#9c1ee9" }}>
						<Link to={`/vessel/${vesselId}/positions-calendar`}>
							<Calendar color="#fff" />
						</Link>
					</Button>
					<Button variant="outline" size="icon" asChild style={{ background: "#5bc0be" }}>
						<Link to={`/vessel/${vesselId}/departments-and-positions`}>
							<GitFork color="#fff" />
						</Link>
					</Button>
					<Button variant="outline" size="icon" asChild style={{ background: "#FB8500" }}>
						<Link to={`/vessel/${vesselId}/payments`}>
							<CreditCard color="#fff" />
						</Link>
					</Button>
					<Button variant="outline" size="icon" asChild style={{ background: "#ffbe0b" }}>
						<Link to={`/vessel/${vesselId}/managers`}>
							<UserCog color="#fff" />
						</Link>
					</Button>
					<Button variant="outline" size="icon" asChild style={{ background: "#8ECAE6" }}>
						<Link to={`/vessel/${vesselId}/crew-members`}>
							<Users color="#fff" />
						</Link>
					</Button>
					<RemoveVesselDialog vesselId={vesselId} />
				</div>
			);
		},
	},
];
