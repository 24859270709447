import { apiSlice } from "@/app/api/apiSlice";

export const notesApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getCrewMemberNotesRelatedWithCalendar: builder.query({
			query: ({ vesselId, usersIds, startDate, endDate }) => `/api/vessels/${vesselId}/notes?users=[${usersIds}]&start_date=${startDate}&end_date=${endDate}`,
			providesTags: (_result, _error, { vesselId, usersIds, startDate, endDate }) => [
				{ type: "Notes", id: `${vesselId}-${usersIds}-${startDate}-${endDate}` }
			],
		})
	}),
	overrideExisting: true,
});

export const {
	useGetCrewMemberNotesRelatedWithCalendarQuery,
} = notesApiSlice;
