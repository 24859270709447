/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { type ReactNode, useState, useMemo, useEffect, useLayoutEffect } from "react";
import { CalendarMinus2 as CalendarMinus2Icon, CalendarX as CalendarXIcon } from "lucide-react";

import { WhiteBox } from "@/components/WhiteBox";
import { Button } from "@/components/ui/button";
import * as Tooltip from '@radix-ui/react-tooltip';
import { cn } from "@/lib/utils";
import { isToday, format as formatFns, getMonth, getYear, differenceInDays, differenceInMonths, addDays, getDate } from "date-fns";
import { ButtonLoader } from "@/components/ButtonLoader";
import { DAY_TYPE } from "@/utils/calendarUtils";
import RotatLogo from "@/assets/logo-small.svg";
import { useGetAppConfigQuery } from "@/app/api/slices/appConfigApiSlice";
import { useGetAvailableDatesQuery, useGetCalendarDataQuery } from "@/app/api/slices/shipCalendarApiSlice";
import { RotationCalendarDayKindDialog } from "../dialogs/RotationCalendarDayKindDialog";
import { useGetCellColor } from "@/hooks/useGetCellColor";
import { SelectYear } from "./SelectYear";
import { useDateFormatter } from "@/hooks/useDateFormatter";
import { useCurrentVesselId } from "@/hooks/useCurrentVesselId";
import { useGetDepartmentsAndPositionsQuery } from "@/app/api/slices/admin/departmentsAndPositionsApiSlice";
import { Filters } from "./Filters";
import { AdaptModal } from "@/components/calendar/Modals/AdaptModal";

const months = [
	{ label: 'January', value: 1 },
	{ label: 'February', value: 2 },
	{ label: 'March', value: 3 },
	{ label: 'April', value: 4 },
	{ label: 'May', value: 5 },
	{ label: 'June', value: 6 },
	{ label: 'July', value: 7 },
	{ label: 'August', value: 8 },
	{ label: 'September', value: 9 },
	{ label: 'October', value: 10 },
	{ label: 'November', value: 11 },
	{ label: 'December', value: 12 }
]; 

const skeletonLoaderData = [
    {
        "positions": [
            {
                "crew": [
                    {
                        "calendar": []
                    }
                ]
            },
            {
                "crew": [
                    {
                        "calendar": []
                    }
                ]
            }
        ]
    },
    {
        "positions": [
            {
                "crew": [
                    {
                        "calendar": []
                    }
                ]
            }
        ]
    },
];

const calendarSizesClasses = {
	1: {
		changeLogo: "w-4",
		gap: "gap-0.5",
		days: "min-w-7 px-[6px]",
	},
	2: {
		changeLogo: "w-2.5",
		gap: "gap-px",
		days: "min-w-3 ps-px pe-0.5 text-[10px]",
	}
}

type CalendarProps = {
	selectedCrewMemberId: string | number | undefined;
	setSelectedCrewMemberId: React.Dispatch<React.SetStateAction<string | number | undefined>>;
};

export const Calendar = ({
	selectedCrewMemberId,
	setSelectedCrewMemberId
}: CalendarProps) => {
	const { format } = useDateFormatter();
	const vessel_id = useCurrentVesselId();

	const [showYearDialog, setShowYearDialog] = useState(false);
	const [showFilters, setShowFilters] = useState(false);
	const [selectedGroups, setSelectedGroups] = useState<string | undefined | Group[]>('all');
	const [onOffView, _setOnOffView] = useState<OnOffView>('all');

	const [calendarZoomLevel, setCalendarZoomLevel] = useState(1);

	const [startDate, setStartDate] = useState<string | undefined | null>();
	const [endDate, setEndDate] = useState<string | undefined | null>();
	const [selectedDepartmentsAndPositions, setSelectedDepartmentsAndPositions] = useState<Group[]>([]);
	const [selectedPositionsIds, setSelectedPositionsIds] = useState<number[] | string[]>([]);
	
	const { data: departmentsAndPositionsData, isLoading: isLoadingDepartments } = useGetDepartmentsAndPositionsQuery({ vesselId: vessel_id }, { skip: !vessel_id });
	const { isLoading: isLoadingAvailableDates } = useGetAvailableDatesQuery({ vessel_id: vessel_id });
	const { data: calendarData, isLoading: isLoadingCalendarData, isFetching: isFetchingCalendarData } = useGetCalendarDataQuery({ 
		vessel_id,
		positionIds: selectedPositionsIds,
		onoffview: onOffView,
		hods: false,
		start_date: startDate,
		end_date: endDate
	}, { skip: !vessel_id || !startDate || !endDate || !selectedPositionsIds.length });

	const showFiltersDialogHandler = () => {
		setShowFilters(true);
	}

	const hideFiltersDialogHandler = () => {
		setShowFilters(false);
	}

	const showYearDialogHandler = () => {
		setShowYearDialog(true);
	}

	const hideYearDialogHandler = () => {
		setShowYearDialog(false);
	}

	const selectedDepartmentsAndPositionsHandler = (selected: Group[]) => {
		setSelectedPositionsIds(selected.flatMap( group => group.positions));
		setSelectedDepartmentsAndPositions(selected);
		setSelectedGroups('custom');
	}

	useLayoutEffect(() => {
		if(startDate && endDate) {
			const monthDifference = differenceInMonths(new Date(endDate), new Date(startDate));

			if(monthDifference >= 1) {
				setCalendarZoomLevel(2);
			} else {
				setCalendarZoomLevel(1);
			}
		}
	}, [startDate, endDate])

	useEffect(() => {
		if(departmentsAndPositionsData?.data) {
			const _selectedDepartmentsAndPositions = departmentsAndPositionsData?.data?.map( department => ({
				department: department.id,
				positions: department.positions.map( position => position.id)
			}))

			setSelectedPositionsIds(_selectedDepartmentsAndPositions.flatMap( group => group.positions));
			setSelectedDepartmentsAndPositions(_selectedDepartmentsAndPositions);
		}
	}, [departmentsAndPositionsData]);

	const [showDayKindModal, setShowDayKindModal] = useState(false);
	const [defaultDayKind, setDefaultDayKind] = useState<string | undefined>();
	const [dayKindStartDate, setDayKindStartDate] = useState<Date | undefined>();
	const [crewMemberData, setCrewMemberData] = useState<{id: number; first_name: string; last_name: string; rotation: string; position_id: number; partner_id: number} | null | undefined>();

	const openDayKindModalHandler = () => {
		setShowDayKindModal(true);
	}

	const closeDayKindModalHandler = () => {
		setShowDayKindModal(false);
		setDefaultDayKind(undefined);
		setDayKindStartDate(undefined);
	}

	const [moveChangeDay, setMoveChangeDay] = useState<{from: null | string, to: null | string}>({
		from: null,
		to: null
	});

	const onCloseMoveChangeDayModal = () => {
		setMoveChangeDay({
			from: null,
			to: null
		});
	}

	const onClickDayHandler = ({	
		day,
		crewMemberData
	}: {
		day: {
			date: string;
			type: string;
		},
		crewMemberData: {
			id: number;
			first_name: string;
			last_name: string;
			rotation: string;
			position_id: number;
			partner_id: number;
		}
	}) => {
		if(day.type === 'undefined') return;

		setCrewMemberData(crewMemberData);

		if((day.type === DAY_TYPE.Change || day.type === DAY_TYPE.TravelAndChange)) {
			if(moveChangeDay.from === null) {
				setMoveChangeDay(prevState => ({
					...prevState,
					from: day.date
				}));
				return;
			}
		}
		if(moveChangeDay.from !== null && moveChangeDay.to === null) {
			if(moveChangeDay.from === day.date) {
				setMoveChangeDay({
					from: null,
					to: null
				});
			} else {
				setMoveChangeDay(prevState => ({
					...prevState,
					to: day.date
				}));
				return;
			}
		}

		setDefaultDayKind(day.type);
		setDayKindStartDate(new Date(day.date));
		openDayKindModalHandler();
	}

	const generateMonthCells = ({crewMember, calendarData} : {
		crewMember: {
			id: number;
			first_name: string;
			last_name: string;
			rotation: string;
			position_id: number;
			partner_id: number;
		};
		calendarData: {
			duration: number;
			start_date: string;
			type: string;
		}[]
	}) => {
		if(!calendarData) return null;

		return calendarData.map((day: {
			duration: number;
			start_date: string;
			type: string;
		}) => {
			return (
				[...Array(day.duration)].map((_, index) => {
					const date = addDays(day.start_date, index);
					const formattedDate = formatFns(date, "y-MM-dd");

					return (
						<CalendarCell 
							key={index}
							label={((day.type === DAY_TYPE.Change) || (day.type === DAY_TYPE.TravelAndChange)) && (
								<img src={RotatLogo} alt="Rotat Logo" className={cn("inline-block", calendarSizesClasses[calendarZoomLevel].changeLogo)}/>
							)}
							onClick={() => onClickDayHandler({
								day: {
									date: formattedDate, 
									type: day.type
								},
								crewMemberData: crewMember
							})}

							type={ (formattedDate === moveChangeDay.from) && (crewMemberData.id === crewMember?.id) ?  "highlight" : day.type}
							documents={undefined}
							onClickDocument={() => {}}
							date={date}
							showTooltip={true}
						/>
					)
				})
			);
		});
	}

	const selectCrewMemberHandler = (crewMember: any) => {
		setSelectedCrewMemberId(crewMember);
	}

	const setSelectedGroupsHandler = (groups: string | undefined | Group[]) => {
		if(groups === selectedGroups) return;

		setSelectedGroups(prevState => {
			if(prevState === groups) return 'custom';
			return groups;
		});

		if(['all'].includes(groups)) {
			if(!departmentsAndPositionsData) return;

			const _selectedDepartmentsAndPositions = departmentsAndPositionsData?.data?.map( department => ({
				department: department.id,
				positions: department.positions.map( position => position.id)
			}))

			setSelectedPositionsIds(_selectedDepartmentsAndPositions.flatMap( group => group.positions));
			setSelectedDepartmentsAndPositions(_selectedDepartmentsAndPositions);
		}
	}

	const getShortNameOfDay = ({year, month, day}: {year: number, month: number, day: number}) => {
		const date = new Date(year, month - 1, day);
		return formatFns(date, "EEE");
	};

	const isCurrentDate = (date: string) => {
		return isToday(date);
	}

	const getDaysInMonth = (month: number | string, year: number | string) => {
		const monthNum = Number(month);
		const yearNum = Number(year);
		const daysInMonths = [31, (yearNum % 4 === 0 && (yearNum % 100 !== 0 || yearNum % 400 === 0)) ? 29 : 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
		return daysInMonths[monthNum - 1];
	}

	const getMonthName = (month: number, months: {
		label: string;
		value: number;
	}[]) => {
		return months.find( _month => _month.value === month)?.label;
	}

	const calendarMonthsData = useMemo(() => {
		if(!startDate || !endDate) return [];

		const d_startDate = new Date(startDate);
		const d_endDate = new Date(endDate);

		const result = [];
		let currentDate = new Date(d_startDate);

		while(currentDate <= d_endDate) {
			const currentMonth = getMonth(currentDate) + 1;
			const currentYear = getYear(currentDate);

			result.push({
				days: getDaysInMonth(currentMonth, currentYear),
				month: currentMonth,
				year: currentYear,
				name: getMonthName(currentMonth, months)
			});
			currentDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1);
		}

		return result;
	}, [startDate, endDate]);

	const calendarDaysData = useMemo(() => {
		if(!calendarData?.data || !startDate || !endDate) return null;

		const dayDifference = differenceInDays(new Date(endDate), new Date(startDate)) + 1;

		const preparedData = calendarData.data.departments.map((department: DepartmentInterface[]) => {
			const positions = department.positions.map((position: PositionInterface) => {
				const crew = position.crew.map((crewMember: CrewInterface) => {

					const calendarWithEmptyDays = {};
					for(let i = 0; i < dayDifference; i++) {
						const currentDate = addDays(startDate, i);
						const month = getMonth(currentDate) + 1;
						const year = getYear(currentDate);
						const day = getDate(currentDate);
						const monthKey = `${year}-${month}-${day}`;
						calendarWithEmptyDays[monthKey] = 'undefined';
					}

					const currentCalendar = crewMember.calendar.reduce((acc, calendar) => {
						const _calendar = {};
						for(let i = 0; i < calendar.duration; i++) {
							const currentDate = addDays(calendar.start_date, i);
							const month = getMonth(currentDate) + 1;
							const year = getYear(currentDate);
							const day = getDate(currentDate);
							const monthKey = `${year}-${month}-${day}`;
							_calendar[monthKey] = calendar.type;
						}

						return {...acc, ..._calendar};
					}, {});

					const mergedCalendar = {...calendarWithEmptyDays, ...currentCalendar};

					const sortedCalendar = Object.entries(mergedCalendar).reduce((acc, [key, value]) => {
						const [year, month] = key.split("-");
						const monthKey = `${year}-${month}`;
						if(!acc[monthKey]) {
							acc[monthKey] = [];
						}
						acc[monthKey].push({
							type: value,
							duration: 1,
							start_date: key
						});
						return acc;
					}, {});

					const preparedCalendar = Object.entries(sortedCalendar).reduce((acc, [monthKey, days]) => {
						acc[monthKey] = days.reduce((acc, day) => {
							const lastDay = acc[acc.length - 1];
							if(lastDay && lastDay.type === day.type) {
								lastDay.duration += 1;
							} else {
								acc.push({...day});
							}
							return acc;
						}, []);

						return acc;
					}, {});

					return {...crewMember, calendar: preparedCalendar};
				});

				return {...position, crew}
			});

			return {...department, positions};
		})


		return preparedData;
	}, [calendarData, startDate, endDate]);

	return (
		<>
			<AdaptModal
				show={moveChangeDay.from !== null && moveChangeDay.to !== null}
				onHide={onCloseMoveChangeDayModal}
				defaultStartDate={moveChangeDay.from}
				defaultEndDate={moveChangeDay.to}

				crewMemberId={crewMemberData?.id}
    			crewMemberName={crewMemberData?.first_name ? `${crewMemberData?.first_name} ${crewMemberData?.last_name}` : ""}

				rotation={crewMemberData?.rotation}
				positionId={crewMemberData?.position_id}
			/>

			<SelectYear 
				show={showYearDialog}
				onHide={hideYearDialogHandler}
				setStartDate={setStartDate}
				setEndDate={setEndDate}
			/>

			<Filters 
				show={showFilters}
				onHide={hideFiltersDialogHandler}
				defaultOnOffView={onOffView}

				selectedDepartmentsAndPositions={selectedDepartmentsAndPositions}
				setSelectedDepartmentsAndPositions={selectedDepartmentsAndPositionsHandler}

				data={departmentsAndPositionsData?.data}
			/>

			<RotationCalendarDayKindDialog 
				closeDayKindModalHandler={closeDayKindModalHandler}
				showDayKindModal={showDayKindModal}
				vessel_id={vessel_id}
				user_id={crewMemberData?.id}
				userName={`${crewMemberData?.first_name} ${crewMemberData?.last_name}`}
				setDayKindStartDate={setDayKindStartDate}
				dayKindStartDate={dayKindStartDate}
				defaultDayKind={defaultDayKind}
			/>

			<WhiteBox className="relative flex flex-col min-h-96 grow overflow-y-auto">

				<div className="relative flex flex-col grow py-4">
					
					<div className="flex justify-between">
						<div className="flex">
							<div className="pe-3 w-52">
								<Button disabled={isLoadingDepartments} className={`w-full h-12 ${ selectedGroups === 'all' ? 'bg-orange-300 hover:bg-orange-400' : '' }`} variant='outline' onClick={() => setSelectedGroupsHandler('all')}>
									{ isLoadingDepartments 
										? (<ButtonLoader isLoading={true} />) 
										: "All crew"
									}
								</Button>
							</div>
							<div className="pe-3 w-52">
								<Button disabled={true} className={`w-full h-12 ${ selectedGroups === 'hods' ? 'bg-orange-300' : '' }`} variant='outline' onClick={() => setSelectedGroupsHandler('hods')}>
									{ isLoadingDepartments 
										? (<ButtonLoader isLoading={true} />) 
										: "HODs"
									}
								</Button>
							</div>
						</div>

						<div className="flex"> 
							<div className="grow max-w-52 pe-3">
								<Button disabled={isLoadingDepartments} className="w-full h-12 flex" variant='outline' onClick={() => setCalendarZoomLevel(prev => prev === 1 ? 2 : 1)}>
									{ calendarZoomLevel === 1
										? (<CalendarXIcon />)
										: (<CalendarMinus2Icon />)
									}
								</Button>
							</div>

							<div className="grow max-w-52">
								<Button disabled={isLoadingDepartments} className="w-full h-12 flex" variant='outline' onClick={showFiltersDialogHandler}>
									{ isLoadingDepartments 
										? (<ButtonLoader isLoading={true} />) 
										: "Filters"
									}
								</Button>
							</div>
						</div>
					</div>

					<hr className="my-2" />

					<div className="flex">
						{/* Labels */}
						<div className="pe-3 w-52">
							<CalendarRowLabel label="Selected year and month(s):" />
						</div>

						{/* Data */}
						<div className="grow flex flex-col">
							<div className="mb-2">
								<CalendarCell label={
									isLoadingAvailableDates
									? <ButtonLoader isLoading={true} />
									: <p><span className="font-medium">{format(startDate, "MMM d, yyyy")}</span> {startDate && endDate ? 'to' : ''} <span className="font-medium">{format(endDate, "MMM d, yyyy")}</span></p>
								} className="" onClick={showYearDialogHandler}/>
							</div>
						</div>
					</div>

					
					<div className="flex relative">
						{/* Labels */}
						{ (startDate && endDate) && (
							<div className="pe-3 w-52 shrink-0">
								<div className="mb-2 h-12 border border-transparent"></div>
								<CalendarRowLabel label="Day" />

								{ (isLoadingCalendarData || isFetchingCalendarData) && (skeletonLoaderData.map( (department, index) => (
									<div className="grow" key={index}>

										<div className="h-3.5 text-sm text-slate-400 leading-none">{""}</div>
										<hr className="pb-1"/>
										
										{ department.positions.map( (position, index) => (
											<div className="grow" key={index}>
												<div className="mb-2">
													{ position.crew.map( (crewMember, index) => (
														<CalendarRowLabel 
															key={index} 
															label={(
																<div className="flex flex-col">
																	<div className="leading-none mb-[2px]">{""} {""}</div>
																	<div className="text-xs font-normal leading-none">{""}</div>
																</div>
															)} 
															className={'bg-slate-100 animate-pulse'}
														/>
													))}
												</div>
											</div>
										))}
									</div>
								)))}

								{ (!isLoadingCalendarData && !isFetchingCalendarData) && (calendarData?.data?.departments?.map( department => (
									<div className="grow" key={department.id}>

										<div className="h-3.5 text-sm text-slate-400 leading-none">{department.name || "-"}</div>
										<hr className="pb-1"/>
										
										{ department.positions.map( position => (
											<div className="grow" key={position.id}>
												<div className="mb-2">
													{ position.crew.map( crewMember => (
														<CalendarRowLabel 
															key={crewMember.id} 
															label={(
																<div className="flex flex-col">
																	<div className="leading-none mb-[2px]">{crewMember.first_name || "-"} {crewMember.last_name || "-"}</div>
																	<div className="text-xs font-normal leading-none">{position.name || "-"}</div>
																</div>
															)} 
															isClickable={true}
															onClick={() => selectCrewMemberHandler(crewMember.id)}
															className={selectedCrewMemberId === crewMember.id ? "bg-orange-300" : "bg-slate-100"}
														/>
													))}
												</div>
											</div>
										))}
									</div>
								)))}
							</div>
						)}

						{/* Data */}
						<div className="flex overflow-y-auto relative grow">
							{ calendarMonthsData?.map((calendarMonthData) => (
								<div className="grow px-2 mb-1 border-e first:ps-0 last:border-none last:pe-0" key={calendarMonthData.name}>
									<div className="flex flex-col relative">
										<div className="mb-2">
											<CalendarCell 
												label={calendarMonthData.name}
											/>
										</div>
									
										<div className={cn("mb-2 flex shrink-0", calendarSizesClasses[calendarZoomLevel].gap)}>
											{[...Array(calendarMonthData.days)].map((_, index) => (
												<CalendarCell 
													key={index}
													label={(
														<div className="flex flex-col justify-center items-center">
															<div>{ index + 1 }</div>
															{ (calendarZoomLevel === 1) && (
																<div className="text-xs">{ getShortNameOfDay({
																	year: calendarMonthData.year,
																	month: calendarMonthData.month,
																	day: index + 1
																}) }</div>
															)}
														</div>
													)}
													className={cn(isCurrentDate(`${calendarMonthData.year}-${calendarMonthData.month}-${index+1}`) && 'calendar-current-day', calendarSizesClasses[calendarZoomLevel].days)}
												/>
											))}
										</div>
									</div>

									{ (isLoadingCalendarData || isFetchingCalendarData) && (skeletonLoaderData?.map( (department, index) => (
										<div className="grow" key={index}>

											<div className="h-3.5"></div>
											<hr className="pb-1"/>

											{ department.positions.map( (position, index) => (
												<div className="grow" key={index}>
														{ position.crew.map( (crewMember, index) => 
															<div key={index} className={cn("mb-2 flex", calendarSizesClasses[calendarZoomLevel].gap)}>
																{[...Array(calendarMonthData.days)].map((_, index) => (
																	<CalendarCell 
																		key={index}
																		label={(
																			<div className="flex flex-col justify-center items-center">
																				<div>{""}</div>
																				<div className="text-xs">{""}</div>
																			</div>
																		)}
																		className={'bg-slate-100 animate-pulse'}
																	/>
																))}
															</div>
														)}
												</div>
											))}

										</div>
									)))}

									{ (!isLoadingCalendarData && !isFetchingCalendarData) && (calendarDaysData?.map( department => (
										<div className="grow" key={department.id}>

											<div className="h-3.5"></div>
											<hr className="pb-1"/>

											{ department.positions.map( position => (
												<div className="grow" key={position.id}>
														{ position.crew.map( crewMember => 
															<div key={crewMember.id} className={cn("mb-2 flex", calendarSizesClasses[calendarZoomLevel].gap)}>
																{generateMonthCells({crewMember: crewMember ,calendarData: crewMember.calendar[`${calendarMonthData.year}-${calendarMonthData.month}`]})}
															</div>
														)}
												</div>
											))}

										</div>
									)))}
								</div>
							))}

						</div>

					</div>
				</div>

			</WhiteBox>
		</>
	);
};


const CalendarRowLabel = ({ 
	label, 
	className="bg-slate-100",

	isClickable=false,
	onClick=()=>{}
}: { 
	label?: ReactNode, 
	className?: string,

	isClickable?: boolean,
	onClick?: () => void;
}) => {
	return (
		<div 
			className={cn(`mb-2 px-3 h-12 border rounded-md flex justify-center items-center text-center text-sm font-medium`, className, isClickable ? 'cursor-pointer' : '')}
			onClick={onClick}
		>
			{label}
		</div>
	);
}

const CalendarCell = ({ 
	label, 
	onClick=()=>{}, 
	className="bg-slate-50",
	type="",
	documents,
	onClickDocument,
	date,
	showTooltip=false
}: { 
	label?: ReactNode, 
	onClick?: () => void, 
	className?: string,
	type?: string,
	documents?: FormattedDocuments[] | undefined | object;
	onClickDocument?: (id: string | number) => void;
	date?: Date; 
	showTooltip?: boolean;
}) => {
	const { format } = useDateFormatter();
	const { data: configData } = useGetAppConfigQuery({});
	const formattedDate = date ? formatFns(date, "y-MM-dd") : "-";

	const { getClassFromType } = useGetCellColor();

	if(!showTooltip) {
		return (
			<div onClick={onClick} 
				style={{flexBasis: '100%'}} 
				className={cn(`overflow-hidden cursor-pointer grow h-12 border hover:border-slate-400 hover:duration-300 rounded-md flex justify-center items-center text-center text-sm`, className)}>
				{label}
			</div>
		)
	}

	return (
		<Tooltip.Provider>
			<Tooltip.Root>
				<Tooltip.Trigger asChild>
					<div onClick={onClick} style={{flexBasis: '100%', background: getClassFromType(type)}} className={cn(`min-w-3 overflow-hidden cursor-pointer grow h-12 border hover:border-slate-400 duration-300 rounded-md flex justify-center items-center text-center text-sm`, className)}>
						{label}
					</div>
				</Tooltip.Trigger>
				<Tooltip.Portal>
					<Tooltip.Content className="TooltipContent" sideOffset={5}>
						{ showTooltip ? (
							<div className="flex flex-col">
								<div className="text-xs lg:text-sm font-medium capitalize">{ configData?.data?.day_types?.[type] }</div>
								<div className="text-xs lg:text-sm">{ format(formattedDate) }</div>

								{ (documents && documents[formattedDate]) && (
									<div className="mt-2">
										<div className="text-xs lg:text-sm font-medium capitalize">Related documents: </div>
								
										{documents[formattedDate].map((doc: {id: string | number, title: string}) => {
											return (
												<div key={doc.id}>
													{/* <a href={doc.url} target="_blank" rel="noreferrer" className="text-xs lg:text-sm text-primary underline">{doc.name}</a> */}
													<p onClick={() => onClickDocument(doc.id)} className="text-xs lg:text-sm text-primary underline cursor-pointer">{doc.title}</p>
												</div>
											);
										})}
								
									</div>
								) }
							</div>
						) : null }
					</Tooltip.Content>
				</Tooltip.Portal>
				</Tooltip.Root>
    	</Tooltip.Provider>
	);
}

type OnOffView = 'all' | 'on' | 'off';
interface FormattedDocuments {
	[key: string]: {
		title: string;
		id: string | number;
	};
}
type Group = {
	department: string | number;
	positions: string[] | number[];
}

interface CalendarInterface {
	duration: number;
	start_date: string;
	end_date: string;
	type: string;
}

interface CrewInterface {
	id: number;
	first_name: string;
	last_name: string;
	isHOD: boolean;
	calendar: CalendarInterface[];
}

interface PositionInterface {
	id: number;
	name: string;
	crew: CrewInterface[];
}

interface DepartmentInterface {
	id: number;
	name: string;
	positions: PositionInterface[];
}
