import * as Sentry from "@sentry/react";
import { createBrowserRouter } from "react-router-dom";

import { AdminVesselPagesLayout } from "@/components/layouts/AdminVesselPagesLayout";
import { AppLayout } from "@/components/layouts/AppLayout";
import { Layout } from "@/components/layouts/Layout";

import { ProtectedAdminOutlet } from "@/components/outlets/ProtectedAdminOutlet";
import { ProtectedManagerOutlet } from "@/components/outlets/ProtectedManagerOutlet";
import { ProtectedOutlet } from "@/components/outlets/ProtectedOutlet";

import { JoyrideWrapper } from "@/hocs/JoyrideWrapper";

import { PublicPagesOutlet } from "@/components/outlets/PublicPagesOutlet";
import { ErrorPage } from "@/pages/ErrorPage";
import { ForgotPasswordPage } from "@/pages/ForgotPasswordPage";
import { ResetPasswordPage } from "@/pages/ResetPasswordPage";
import { SetPasswordPage } from "@/pages/SetPasswordPage";
import { AdminLoginPage } from "@/pages/admin/AdminLoginPage";
import { CrewMembersListingPage } from "@/pages/admin/CrewMembersListingPage";
import { FleetsListingPage } from "@/pages/admin/FleetsListingPage";
import { MagicLinksPage } from "@/pages/admin/MagicLinksPage";
import { ManagersListingPage } from "@/pages/admin/ManagersListingPage";
import { SubscriptionsSettingsPage } from "@/pages/admin/SubscriptionsSettingsPage";
import { VesselBrandingPage } from "@/pages/admin/VesselBrandingPage";
import { VesselDepartmentsAndPositionsPage } from "@/pages/admin/VesselDepartmentsAndPositionsPage";
import { VesselPaymentsPage } from "@/pages/admin/VesselPaymentsPage";
import { VesselsListingPage } from "@/pages/admin/VesselsListingPage";
import { CrewMemberDocumentsPage } from "@/pages/manager/CrewMemberDocumentsPage";
import { ManagerLoginPage } from "@/pages/manager/ManagerLoginPage";
import { MyVesselPage } from "@/pages/manager/MyVesselPage";
import { PaymentCancelPage } from "@/pages/manager/PaymentCancelPage";
import { PaymentSuccessPage } from "@/pages/manager/PaymentSuccessPage";
import { PaymentsPage } from "@/pages/manager/PaymentsPage";
import { PositionsPage } from "@/pages/manager/PositionsPage";
import { RotationCalendarPage } from "@/pages/manager/RotationCalendarPage";
import { ShipCalendar } from "@/pages/manager/ShipCalendar";
import { AddArticlePage } from "@/pages/shared/AddArticlePage";
import { EditArticlePage } from "@/pages/shared/EditArticlePage";
import { KnowledgeBasePage } from "@/pages/shared/KnowledgeBasePage";
import { NotificationsPage } from "@/pages/shared/NotificationsPage";
import { ProfilePage } from "@/pages/shared/ProfilePage";
import { SuggestionsPage } from "@/pages/shared/SuggestionsPage";

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

export const router = sentryCreateBrowserRouter([
	{
		element: <Layout />,
		errorElement: <ErrorPage />,
		children: [
			{
				element: <PublicPagesOutlet />,
				children: [
					{
						path: "login",
						element: <ManagerLoginPage />,
					},
					{
						path: "admin",
						element: <AdminLoginPage />,
					},
					{
						path: "forgot-password",
						element: <ForgotPasswordPage />,
					},
					{
						path: "set-password",
						element: <SetPasswordPage />,
					},
					{
						path: "reset-password",
						element: <ResetPasswordPage />,
					},
				],
			},
			{
				element: <AppLayout />,
				children: [
					// pages for admin only
					{
						element: <ProtectedAdminOutlet />,
						children: [
							{
								path: "fleets",
								element: <FleetsListingPage />,
							},
							{
								path: "vessels",
								element: <VesselsListingPage />,
							},
							{
								element: <AdminVesselPagesLayout />,
								children: [
									{
										path: "vessel/:vesselId/branding",
										element: <VesselBrandingPage />,
									},
									{
										path: "vessel/:vesselId/positions-calendar",
										element: <PositionsPage />,
									},
									{
										path: "vessel/:vesselId/payments",
										element: <VesselPaymentsPage />,
									},
									{
										path: "vessel/:vesselId/managers",
										element: <ManagersListingPage />,
									},
									{
										path: "vessel/:vesselId/crew-members",
										element: <CrewMembersListingPage />,
									},
									{
										path: "vessel/:vesselId/crew-members/:user_id/rotation-calendar",
										element: <RotationCalendarPage />,
									},
									{
										path: "vessel/:vesselId/departments-and-positions",
										element: <VesselDepartmentsAndPositionsPage />,
									},
								],
							},
							{
								path: "subscriptions",
								element: <SubscriptionsSettingsPage />,
							},
							{
								path: "magic-links",
								element: <MagicLinksPage />,
							},
						],
					},
					{
						element: <JoyrideWrapper />,
						children: [
							// pages for manager only
							{
								element: <ProtectedManagerOutlet />,
								children: [
									{
										path: "",
										element: <MyVesselPage />,
									},
									{
										path: "ship-calendar",
										element: <ShipCalendar />,
									},
									{
										path: "positions",
										element: <PositionsPage />,
									},
									{
										path: "crew-members",
										element: <CrewMembersListingPage />,
									},
									{
										path: "crew-members/:userId/documents",
										element: <CrewMemberDocumentsPage />,
									},
									{
										path: "crew-members/:user_id/rotation-calendar",
										element: <RotationCalendarPage />,
									},
									{
										path: "payments",
										element: <PaymentsPage />,
									},
									{
										path: "payment-success",
										element: <PaymentSuccessPage />,
									},
									{
										path: "payment-cancel",
										element: <PaymentCancelPage />,
									},
								],
							},
							// pages with shared slug
							{
								element: <ProtectedOutlet />,
								children: [
									{
										path: "suggestions",
										element: <SuggestionsPage />,
									},
									{
										path: "notifications",
										element: <NotificationsPage />,
									},
									{
										path: "knowledge-base",
										element: <KnowledgeBasePage />,
									},
									{
										path: "knowledge-base/add",
										element: <AddArticlePage />,
									},
									{
										path: "knowledge-base/edit/:articleId",
										element: <EditArticlePage />,
									},
									{
										path: "settings",
										element: <ProfilePage />,
									},
								],
							},
						],
					},
				],
			},
		],
	},
]);
