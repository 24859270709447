import { useState } from "react";

import { Calendar } from "@/components/ship-calendar/Calendar";
import { Controls } from "@/components/ship-calendar/Controls";


export const ShipCalendar = () => {
	const [selectedCrewMemberId, setSelectedCrewMemberId] = useState<number | string | undefined>();

	return (
		<div className="flex flex-col lg:flex-row">
			<Calendar 
				selectedCrewMemberId={selectedCrewMemberId}
				setSelectedCrewMemberId={setSelectedCrewMemberId}
			/>

			<section className="hidden lg:block">
				<Controls 
					selectedCrewMemberId={selectedCrewMemberId}
					minimizable={true}
					isMinimizedDefault={true}
				/>
			</section>
		</div>
	);
};
