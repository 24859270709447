import { emailZodRule } from "@/lib/zod";
import * as z from "zod";

export const formSchema = z.object({
	client: z.string().min(1, { message: "Client name must contain at least 1 character" }),
	email: emailZodRule,
	fleet: z.coerce.boolean(),
});

export type FormType = z.infer<typeof formSchema>;
