import { useCallback } from "react";

import {
	useGetCalendarColorsQuery
} from "@/app/api/slices/appConfigApiSlice";

export function useGetCellColor() {
    const { data: calendarColors, isLoading } = useGetCalendarColorsQuery({});

    const getClassFromType = useCallback((type: string) => {
        if(!calendarColors) return "";

        if(type === "highlight") {
            return "#FB923C";
        }

        const color = calendarColors?.data?.[type];
        return  color ? `${color}` : "";
    }, [calendarColors]);

	return {
        getClassFromType,
        isLoading
    }
}
