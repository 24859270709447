import { useMemo, useRef, useState, useEffect, useCallback } from "react";
import { format as formatFns, addDays } from "date-fns";
import {
	type CalendarPartInterface, 
	type RotationInterface,
	type CrewMemberInterface,
	type RotationDatesInfoInterface
} from "@/types/Calendar";
import { Button } from "@/components/ui/button";

const TURN_ON_ARROWS = true;

type Props = {
	calendarDataGroup: {rotations: RotationInterface[]}[] | CrewMemberInterface[] | undefined,
	pageHandler: (page: {dir: string, currentPage: number | undefined}) => void,
	page: undefined | number,
	isLoading: boolean,
	maxPages: number,
	startDate: string | undefined,
	startDateHandler: (date: string) => void
};

export const CalendarControls = ({
	calendarDataGroup,
	pageHandler,
	page,
	isLoading,
	maxPages,
	startDate,
	startDateHandler
}: Props) => {
	const containerRef = useRef<HTMLDivElement>(null);
	const buttonRefs = useRef<(HTMLButtonElement | null)[]>([]);
	const [paths, setPaths] = useState<({path: string;type: string;} | null)[]>([]);

	const connections = useMemo(() => {
		// ie: { end_date: "2022-01-01", start_date: "2021-12-01", type: "onboard" }
		if(!calendarDataGroup) return;
		return calendarDataGroup.map(({rotations}, index) => {
			return {
				isPartner: index === 1,
				connections: rotations?.map(({onboard, offboard}: {onboard: RotationDatesInfoInterface, offboard: RotationDatesInfoInterface}) => {
					return [
						// { start_date: onboard.start_date, end_date: format( addDays(new Date(onboard.end_date), 1) ,"yyyy-MM-dd" ), type: "onboard" },
						{ start_date: onboard.start_date, end_date: onboard.end_date, type: "onboard" },
						{ start_date: offboard.start_date, end_date: formatFns( addDays(new Date(offboard.end_date), 1), "y-MM-dd"), type: "offboard" },
						// { start_date: onboard.start_date, end_date: onboard.end_date, type: "onboard" },
						// { start_date: offboard.start_date, end_date: offboard.end_date, type: "offboard" }
					]
				})?.flat()
			}
		})
	}, [calendarDataGroup]);

	const rotationsDayChanges = useMemo(() => {
		if(!calendarDataGroup) return;
		const rotations = calendarDataGroup.map(({rotations}: {rotations: RotationInterface[]}) => {
			return rotations.map(({calendar}: {calendar: CalendarPartInterface[]}) => {
				return calendar.reduce((acc: string[], {type, start_date}) => {
					if(["change_day", "travel_day_change_day"].includes(type)) {
						acc.push(formatFns(start_date, "y-MM-dd"));
					}
					return acc;
				}, [])
			});
		});

		const uniqueDates = Array.from(new Set(rotations.flat(2)));
		const sortedIntoYears = uniqueDates.reduce((acc: {[key: string]: string[]}, date: string) => {
			const year = new Date(date).getFullYear();
			if(!acc[year]) {
				acc[year] = [date];
			} else {
				acc[year].push(date);
			}
			return acc;
		}, {});
		const sortedKeys = Object.keys(sortedIntoYears).sort((a, b) => Number(a) - Number(b));
		const preparedDates = sortedKeys.map((year) => {
			const yearDates = sortedIntoYears[year];
			yearDates.sort((a: string, b: string) => (new Date(a).getTime() - new Date(b).getTime()))
			return yearDates;
		});
		
		return preparedDates;
	}, [calendarDataGroup]);

	const updatePaths = useCallback(() => {
		if(!connections) return;

		const containerRect = containerRef?.current?.getBoundingClientRect();
		if(!containerRect) return;

		const newPaths = connections.reduce<({
			path: string;
			type: string;
		} | null)[]>((acc, {connections, isPartner}) => {
			const paths = connections.map(({start_date, end_date, type}, index) => {
				const div1 = buttonRefs.current.find((el) => el?.dataset?.date === start_date);
				const div2 = buttonRefs.current.find((el) => el?.dataset?.date === end_date);
	
				if (div1 && div2) {
					const rect1 = div1.getBoundingClientRect();
					const rect2 = div2.getBoundingClientRect();
	
					// Middle points in relation to container
					const x1 = rect1.left - containerRect.left + rect1.width / 2;
					let y1 = type === 'onboard' ? rect1.bottom - containerRect.top : rect1.top - containerRect.top;
					const x2 = rect2.left - containerRect.left + rect2.width / 2;
					let y2 = type === 'onboard' ? rect2.bottom - containerRect.top : rect2.top - containerRect.top;

					// Add control points to make the curve
					const xFactor = 20;
					const yFactor = 20;
					const controlX1 = x1 + xFactor;
					let controlY1 = index % 2 === 0 ? y1 + yFactor : y1 - yFactor;
					const controlX2 = x2 - xFactor;
					let controlY2 = index % 2 === 0 ? y2 + yFactor : y2 - yFactor;

					if(isPartner) {
						y1 = type === 'onboard' ? rect1.top - containerRect.top : rect1.bottom - containerRect.top;
						y2 = type === 'onboard' ? rect2.top - containerRect.top : rect2.bottom - containerRect.top;
						controlY1= index % 2 === 0 ? y1 - yFactor : y1 + yFactor;
						controlY2 = index % 2 === 0 ? y2 - yFactor : y2 + yFactor;
					}
			
					// Return SVG path with Bezier curve
					return {
						path: `M ${x1},${y1} C ${controlX1},${controlY1} ${controlX2},${controlY2} ${x2},${y2}`,
						type: isPartner ? type : type === "onboard" ? "offboard" : "onboard"
					};
				  }
				  return null;
			}).filter(path => path);

			return [...acc, ...paths];
		}, []);

		setPaths(newPaths);
	}, [connections]);

	const getIndex = ({
		rotationsDayChanges,
		yearIndex,
		dateIndex
	} : {
		rotationsDayChanges: string[][],
		yearIndex: number,
		dateIndex: number
	}) => {
		const length = rotationsDayChanges.reduce((acc, dates, index) => {
			if(yearIndex <= index) {
				return acc + dates.length;
			}
			return acc;
		}, 0);

		if(yearIndex === 0) {
			return dateIndex;
		}

		return length + dateIndex;
	}

	useEffect(() => {
		updatePaths();
		window.addEventListener("resize", updatePaths);
		return () => {
			window.removeEventListener("resize", updatePaths)
		};
	}, [connections, updatePaths]);
	
	return (
		<div className="mb-3 relative sticky top-0 z-10 w-full bg-white pt-4">
			<div className="flex h-24">
				<div className="grow flex justify-between">
					<div className="me-3 h-[88px] pb-[16px] flex items-end">
						<Button disabled={(page ? (page <= 1) : true) || isLoading} onClick={ () => pageHandler({dir: "prev", currentPage: page})}>
							Previous
						</Button>
					</div>
			
					<div className="grow relative">	
						<div className="absolute w-full flex justify-center">
							<div className="relative flex scroll overflow-x-auto pb-3" ref={containerRef}>
								{ rotationsDayChanges?.map((dates: string[], index: number, arr: string[][]) => (
									<div key={index} className={`pb-2 ${ arr.length === 1 ? "" : index === 0 ? "pe-3" : "ps-3 pb-2 border-l-2" }`}>
										<div className={` pb-3 text-xs font-semibold mb-1 ${ arr.length === 1 ? "text-center" : index === 0 ? "text-end" : "text-start" }`}>
											{new Date(dates[0]).getFullYear()}
										</div>

										<div className="flex items-center">
											{dates.map((date: string, indexDate: number) => (
												<Button ref={(el) => (buttonRefs.current[getIndex({
													rotationsDayChanges,
													yearIndex: index,
													dateIndex: indexDate
												})] = el)} data-date={date} key={date} className={`me-1 last:me-0 ${ date === startDate ? "bg-orange-500" : "" }`} onClick={() => startDateHandler(date)}>
													{new Date(date).toLocaleDateString("en-GB", { day: "numeric", month: "short"})}
												</Button>
											))}
										</div>
									</div>
								))}

								{ TURN_ON_ARROWS && (
									<svg 
										className="absolute top-0 left-0 h-full pointer-events-none" 
										style={{width: containerRef?.current?.scrollWidth || "100%"}}
									>
										<defs>
											<marker
												id="arrow"
												viewBox="0 0 10 10"
												refX="10"
												refY="5"
												markerWidth="8"
												markerHeight="8"
												orient="auto"
											>
												<path d="M 0 0 L 15 5 L 0 10 Z" fill="#2655a3" />
											</marker>

											<marker
												id="arrow2"
												viewBox="0 0 10 10"
												refX="10"
												refY="5"
												markerWidth="8"
												markerHeight="8"
												orient="auto"
											>
												<path d="M 0 0 L 15 5 L 0 10 Z" fill="#609e73" />
											</marker>
										</defs>

										{paths.map((data, index) => (
											<path 
												key={index}
												d={data?.path}
												stroke={data?.type === "onboard" ? "#2655a3" : "#609e73"}
												strokeWidth="1"
												fill="none"
												markerEnd={`url(#arrow${data?.type === "onboard" ? "" : "2"})`}
											/>
										))}
									</svg>
								)}
							</div>
							
						</div>
					</div>

					<div className="ms-3 h-[88px] pb-[16px] flex items-end">
						<Button disabled={(page ? (page >= maxPages) : true) || isLoading} onClick={ () => pageHandler({dir: "next", currentPage: page})}>
							Next
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
};
