/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect } from "react";
import { format as formatFns, getYear, getMonth, lastDayOfMonth, getTime  } from "date-fns";
import { cn } from "@/lib/utils";
import { useDateFormatter } from "@/hooks/useDateFormatter";

import { Button } from "@/components/ui/button";

import { ButtonLoader } from "@/components/ButtonLoader";
import {
	Dialog,
	DialogContent,
	DialogFooter,
	DialogHeader,
	DialogTitle,
	DialogClose
} from "@/components/ui/dialog";
import {
	Select,
	SelectContent,
	SelectGroup,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/components/ui/select";

import { useCurrentVesselId } from "@/hooks/useCurrentVesselId";
import { useGetAvailableDatesQuery } from "@/app/api/slices/shipCalendarApiSlice";

const months = [
	{ label: 'January', value: 1 },
	{ label: 'February', value: 2 },
	{ label: 'March', value: 3 },
	{ label: 'April', value: 4 },
	{ label: 'May', value: 5 },
	{ label: 'June', value: 6 },
	{ label: 'July', value: 7 },
	{ label: 'August', value: 8 },
	{ label: 'September', value: 9 },
	{ label: 'October', value: 10 },
	{ label: 'November', value: 11 },
	{ label: 'December', value: 12 }
]; 

type SelectedYearProps = {
	show: boolean;
	onHide: () => void;
	setStartDate: React.Dispatch<React.SetStateAction<string | null | undefined>>;
	setEndDate: React.Dispatch<React.SetStateAction<string | null | undefined>>;
}
const SelectYear = ({
	show,
	onHide,
	setStartDate,
	setEndDate
}: SelectedYearProps) => {
	const vessel_id = useCurrentVesselId();
	const { format } = useDateFormatter();

	const [selectedYear, setSelectedYear] = useState<string | undefined>();
	const [previouslySelectedTimeRange, setPreviouslySelectedTimeRange] = useState<{
		startDate: null | { year: number; month: number };
		endDate: null | { year: number; month: number };
	}>({
		startDate: null,
		endDate: null
	});

	const [availableYears, setAvailableYears] = useState<number[]>([]);
	const [availableMonths, setAvailableMonths] = useState<number[]>([]);

	const [newSelectedYear, setNewSelectedYear] = useState<string | undefined>();

	const [selectedTimeRange, setSelectedTimeRange] = useState<{
		startDate: null | { year: number; month: number };
		endDate: null | { year: number; month: number };
	}>({
		startDate: null,
		endDate: null
	});

	const { data: getAvailableDatesData, isLoading: isLoadingAvailableDates } = useGetAvailableDatesQuery({ vessel_id: vessel_id });

	const selectedYearHandler = (year: string | undefined) => {
		const availableMonths = getAvailableDatesData?.data?.find( ({ year: _year }:{ year: number }) => String(_year) === String(year))?.months;
		setNewSelectedYear(year);
		setAvailableMonths(availableMonths);
	}

	const selectMonthHandler = ({month, year}: {month: number, year: string | undefined}) => {
		if(!year) return;
		
		if(!selectedTimeRange.startDate) {
			setSelectedTimeRange({
				...selectedTimeRange,
				startDate: { year: Number(year), month }
			});
		} else if(!selectedTimeRange.endDate) {
			const d_startDate = getTime(`${selectedTimeRange.startDate.year}-${selectedTimeRange.startDate.month}`);
			const d_endDate = getTime(`${year}-${month}`);

			if(d_startDate > d_endDate) {
				setSelectedTimeRange({
					startDate: { year: Number(year), month },
					endDate: { year: selectedTimeRange.startDate.year, month: selectedTimeRange.startDate.month }
				});
			} else {
				setSelectedTimeRange({
					...selectedTimeRange,
					endDate: { year: Number(year), month }
				});
			}
		} else {
			setSelectedTimeRange({
				...selectedTimeRange,
				startDate: { year: Number(year), month },
				endDate: null
			});
		}
	}

	const onCloseHandler = () => {
		onHide();
	}

	const onSubmitHandler = () => {
		if(!newSelectedYear || !selectedTimeRange.startDate) return;

		setSelectedYear(newSelectedYear);
		setPreviouslySelectedTimeRange(selectedTimeRange);

		const o_startDate = selectedTimeRange.startDate;
		const o_endDate = selectedTimeRange.endDate;
		if(!o_startDate) return;
		
		const startDateMonth = new Date(`${o_startDate.year}-${o_startDate.month}`);
		const endDateMonth = lastDayOfMonth(new Date(`${o_endDate ? o_endDate.year : o_startDate.year}-${o_endDate ? o_endDate.month : o_startDate.month}`));

		setStartDate(formatFns(startDateMonth, 'yyyy-MM-01'));
		setEndDate(formatFns(endDateMonth, 'yyyy-MM-dd'));

		onCloseHandler();
	}

	const getFormattedStartDate = (monthData: {year: number, month: number} | null) => {
		if(!monthData) return "-";
		return format(new Date(`${monthData.year}-${monthData.month}-01`));
	}

	const getFormattedEndDate = ({startDate, endDate}: {startDate: null | {year: number, month: number}, endDate: null | {year: number, month: number}}) => {
		if(startDate && !endDate) {
			return format(lastDayOfMonth(new Date(`${startDate.year}-${startDate.month}`)));
		} else if(endDate) {
			return format(lastDayOfMonth(new Date(`${endDate.year}-${endDate.month}`)));
		}
		
		return "-";
	}

	const isMonthSelected = ({year, month}: {year: number, month: number}) => {
		const { startDate, endDate } = selectedTimeRange;

		const d_startDate = startDate ? new Date(`${startDate.year}-${startDate.month}-01`) : null;
		const d_endDate = endDate ? new Date(`${endDate.year}-${endDate.month}-01`) : null;

		if(d_startDate && d_endDate) {
			const d_month = new Date(`${year}-${month}-01`);
			if(d_month >= d_startDate && d_month <= d_endDate) return true;
		}
		if(startDate?.year === year && startDate?.month === month) return true;
		if(endDate?.year === year && endDate?.month === month) return true;
		return false
	};

	useEffect(() => {
		if(getAvailableDatesData) {
			const years = getAvailableDatesData?.data?.map( ({ year }:{ year: number }) => year );
			if(!years || !years.length) return;
			
			const now = new Date();
			const currentYear = getYear(now);
			const currentMonth = getMonth(now) + 1;

			const selectedYear = years.includes(currentYear) ? currentYear : years[0];
			const availableMonths = getAvailableDatesData?.data?.find( ({ year }: {year: number}) => year === selectedYear)?.months;
			const selectedMonth = availableMonths?.includes(currentMonth) ? currentMonth : availableMonths?.[0];

			setAvailableYears(years);
			setAvailableMonths(availableMonths);

			setSelectedYear(String(selectedYear));
			setPreviouslySelectedTimeRange(prevState => ({
				...prevState,
				startDate: { year: Number(selectedYear), month: selectedMonth }
			}))

			setNewSelectedYear(String(selectedYear));
			setSelectedTimeRange(prevState => ({
				...prevState,
				startDate: { year: Number(selectedYear), month: selectedMonth }
			}));
			
			const startDateMonth = new Date(`${selectedYear}-${selectedMonth}`);
			const endDateMonth = lastDayOfMonth(new Date(`${selectedYear}-${selectedMonth}`));
			const firstDateOfMonth = formatFns(startDateMonth, 'yyyy-MM-01');
			const lastDateOfMonth = formatFns(endDateMonth, 'yyyy-MM-dd');

			setStartDate(firstDateOfMonth);
			setEndDate(lastDateOfMonth);
		}
	}, [getAvailableDatesData]);

	useEffect(() => {
		if(show) {
			setNewSelectedYear(selectedYear);
			setSelectedTimeRange(previouslySelectedTimeRange);
		}
	}, [show])

	return (
		<Dialog open={show} onOpenChange={onCloseHandler} defaultOpen={false}>
			<DialogContent>
				<DialogHeader>
					<DialogTitle>Select a year and month</DialogTitle>
				</DialogHeader>

				<div className="overflow-y-auto px-1 pt-1">
					<div>
						<Select
							onValueChange={selectedYearHandler}
							value={newSelectedYear}
							disabled={isLoadingAvailableDates}
						>
							<SelectTrigger className="relative">
								<SelectValue placeholder="Select department..." />
								{isLoadingAvailableDates && (
									<div className="absolute inset-0 flex items-center justify-center">
										<ButtonLoader isLoading={true} />
									</div>
								)}
							</SelectTrigger>
							<SelectContent>
								<SelectGroup>
									{availableYears?.map((year: string | number) => (
										<SelectItem key={year} value={String(year)}>
											{year}
										</SelectItem>
									))}
								</SelectGroup>
							</SelectContent>
						</Select>
					</div>

					<hr className="my-5"/>
									
					<div>
						<div className="grid w-full grid-cols-3 gap-2" role="grid" aria-labelledby="month-picker">
							{months.map((month) => (
								<div
									key={month.value}
									className="relative p-0 text-center text-sm focus-within:relative focus-within:z-20 [&:has([aria-selected])]:bg-slate-100 first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md dark:[&:has([aria-selected])]:bg-slate-800"
									role="presentation"
								>
									<button
										name="day"
										className={cn(
											"inline-flex h-9 w-full items-center justify-center rounded-md p-0 text-sm font-normal ring-offset-white transition-colors hover:bg-slate-100 hover:text-slate-900 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-400 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 aria-selected:opacity-100 dark:ring-offset-slate-950 dark:hover:bg-slate-800 dark:hover:text-slate-50 dark:focus-visible:ring-slate-800",
											isMonthSelected({year: Number(newSelectedYear), month:month.value}) && "bg-slate-100 text-slate-900 dark:bg-slate-800 dark:text-slate-50",
										)}
										role="gridcell"
										tabIndex={-1}
										type="button"
										onClick={() => selectMonthHandler({month: month.value, year: newSelectedYear})}
										disabled={!availableMonths?.includes(month.value)}
									>
										{month.label}
									</button>
								</div>
							))}
						</div>
					</div>

					<hr className="mt-5"/>
					<div className="text-end my-1">
						<p className="italic text-sm">Selected time range: 
							<span className="font-medium mx-1">{getFormattedStartDate(selectedTimeRange.startDate)}</span>
							 to 
							<span className="font-medium ms-1">{getFormattedEndDate({startDate: selectedTimeRange.startDate, endDate: selectedTimeRange.endDate})}</span>
						</p>
					</div>
					<hr/>
				</div>

				<DialogFooter>
					<div className="flex w-full justify-end">
						<DialogClose asChild className="me-2">
							<Button type="button" variant="secondary">
								Close
							</Button>
						</DialogClose>

						<Button type="button" onClick={onSubmitHandler} disabled={isLoadingAvailableDates}>
							Submit
						</Button>
					</div>
				</DialogFooter>

			</DialogContent>
		</Dialog>
	)
}

export { SelectYear };
