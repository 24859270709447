import { apiSlice } from "@/app/api/apiSlice";

export const rotationCalendarApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getCrewMemberRotationSettings: builder.query({
			query: ({ vessel_id, user_id }: { vessel_id: numOrStr; user_id: numOrStr }) =>
				`/api/vessels/${vessel_id}/users/${user_id}/rotation-settings`,
			providesTags: (_result, _error, { user_id }) => [
				{ type: "RotationSettings", id: `${user_id}` },
				"LeaveBalance",
			],
		}),
		getCrewMemberContractsList: builder.query({
			query: ({ vessel_id, user_id }: { vessel_id: numOrStr; user_id: numOrStr }) =>
				`/api/vessels/${vessel_id}/users/${user_id}/contracts`,
			providesTags: (_result, _error, { user_id }) => [
				{ type: "RotationContracts", id: `${user_id}` },
			],
		}),
		getCrewMemberRotationCalendarData: builder.query({
			query: ({
				vessel_id,
				user_id,
				page,
				rotations_count,
				contract_id,
			}: GetCrewMemberRotationCalendarDataArgs) =>
				`/api/vessels/${vessel_id}/users/${user_id}/rotation-calendar?page=${page}&rotations_count=${rotations_count}&contract_id=${contract_id}`,
			providesTags: (_result, _error, { user_id, page, rotations_count, contract_id }) => [
				{ type: "RotationCalendar", id: `${user_id}${contract_id}${page}${rotations_count}` },
				"LeaveBalance",
			],
		}),
		createCrewMemberContract: builder.mutation({
			query: ({ vessel_id, user_id, data }: CreateCrewMemberContractArgs) => ({
				url: `/api/vessels/${vessel_id}/users/${user_id}/rotation-settings`,
				method: "POST",
				body: data,
			}),
			invalidatesTags: (_result, error) =>
				error
					? []
					: [
							"Users",
							"RotationContracts",
							"RotationCalendar",
							"PositionData",
							"AvailablePositions",
							"MyVesselInfo",
							"LeaveBalance",
							"ShipCalendarData",
							"CalendarChangePartners"
						],
		}),
		deleteContract: builder.mutation({
			query: ({ vesselId, userId, contractId }: DeleteContractArgs) => ({
				url: `/api/vessels/${vesselId}/users/${userId}/rotation-settings/delete/${contractId}`,
				method: "DELETE",
			}),
			invalidatesTags: (_result, error) =>
				error
					? []
					: [
							"Users",
							"RotationContracts",
							"RotationCalendar",
							"PositionData",
							"AvailablePositions",
							"MyVesselInfo",
							"LeaveBalance",
							"ShipCalendarData",
							"CalendarChangePartners"
						],
		}),
		editCrewMemberRotationCalendar: builder.mutation({
			query: ({ vessel_id, user_id, data }: EditCrewMemberRotationCalendarArgs) => ({
				url: `/api/vessels/${vessel_id}/users/${user_id}/rotation-calendar`,
				method: "PUT",
				body: data,
			}),
			invalidatesTags: (_result, error) =>
				error
					? []
					: [
							"Users",
							"RotationCalendar",
							"PositionData",
							"AvailablePositions",
							"MyVesselInfo",
							"LeaveBalance",
							"ShipCalendarData",
							"CalendarChangePartners",
							"Notes"
						],
		}),
		editContract: builder.mutation({
			query: ({ vessel_id, user_id, data }: EditContractArgs) => ({
				url: `/api/vessels/${vessel_id}/users/${user_id}/rotation-settings`,
				method: "PUT",
				body: data,
			}),
			invalidatesTags: (_result, error) =>
				error
					? []
					: [
							"Users",
							"RotationContracts",
							"RotationCalendar",
							"PositionData",
							"AvailablePositions",
							"MyVesselInfo",
							"LeaveBalance",
							"ShipCalendarData",
							"CalendarChangePartners"
						],
		}),
		editContractEndDate: builder.mutation({
			query: ({ vessel_id, user_id, data }: EditContractEndDateArgs) => ({
				url: `/api/vessels/${vessel_id}/users/${user_id}/rotation-date`,
				method: "PUT",
				body: data,
			}),
			invalidatesTags: (_result, error) =>
				error
					? []
					: [
							"Users",
							"RotationContracts",
							"RotationCalendar",
							"PositionData",
							"MyVesselInfo",
							"LeaveBalance",
							"ShipCalendarData",
							"CalendarChangePartners"
						],
		}),
		checkIfUserHasParnterAtDay: builder.query({
			query: ({ vessel_id, user_id, date }: { vessel_id: string | number | undefined; user_id: number | undefined; date: string | undefined }) =>
				`/api/vessels/${vessel_id}/users/${user_id}/partner-change-day?date=${date}`,
			providesTags: (_result, _error, { user_id, vessel_id, date }) => [
				{ type: "CalendarChangePartners", id: `${vessel_id}-${user_id}-${date}` }
			],
		}),
	}),
	overrideExisting: true,
});

type numOrStr = number | string;

interface GetCrewMemberRotationCalendarDataArgs {
	vessel_id: numOrStr;
	user_id: numOrStr;
	page: number | undefined;
	rotations_count: number;
	contract_id: numOrStr;
}
interface CreateCrewMemberContractArgs {
	vessel_id: numOrStr;
	user_id: numOrStr;
	data: {
		rotation: string | undefined | null;
		start_date: string;
		end_date: string | undefined | null;
		rotation_partner_id: numOrStr | undefined | null;
		isAutomaticallyGenerated: boolean;
		rotationPeriodType: string;
		leaveAllowance: number | undefined | null;
	};
}
interface DeleteContractArgs {
	vesselId: numOrStr;
	userId: numOrStr;
	contractId: numOrStr;
}
interface EditCrewMemberRotationCalendarArgs {
	vessel_id: numOrStr;
	user_id: numOrStr;
	data: {
		id: numOrStr | null | undefined;
		start_date: string;
		end_date: string;
		type: string;
		note: string | null | undefined;
	};
}
interface EditContractArgs {
	vessel_id: numOrStr;
	user_id: numOrStr;
	data: {
		id: numOrStr;
		rotation: string | undefined | null;
		start_date: string;
		end_date: string | undefined | null;
		rotation_partner_id: numOrStr | undefined | null;
		rotationPeriodType: string;
		leaveAllowance: number | undefined | null;
	};
}
interface EditContractEndDateArgs {
	vessel_id: numOrStr;
	user_id: numOrStr;
	data: {
		id: numOrStr;
		end_date: string;
	};
}

export const {
	useGetCrewMemberRotationSettingsQuery,
	useGetCrewMemberContractsListQuery,
	useGetCrewMemberRotationCalendarDataQuery,
	useCreateCrewMemberContractMutation,
	useDeleteContractMutation,
	useEditContractMutation,
	useEditCrewMemberRotationCalendarMutation,
	useEditContractEndDateMutation,
	useCheckIfUserHasParnterAtDayQuery,
} = rotationCalendarApiSlice;
