import {
	CreditCard,
	GitFork,
	GraduationCap,
	Lightbulb,
	Settings,
	Ship,
	TableColumnsSplit,
	Users,
} from "lucide-react";

export const managerMenu = [
	{
		id: "menu-my-vessel",
		title: "My Vessel",
		subtitle: null,
		icon: <Ship />,
		link: "/",
	},
	{
		id: "menu-positions",
		title: "Crew Leave Schedule",
		subtitle: null,
		icon: <TableColumnsSplit />,
		link: "/ship-calendar",
	},
	{
		id: "menu-positions",
		title: "Positions Calendar",
		subtitle: null,
		icon: <GitFork />,
		link: "/positions",
	},
	{
		id: "menu-crew",
		title: "Crew List",
		subtitle: null,
		icon: <Users />,
		link: "/crew-members",
	},
	{
		id: "menu-payments",
		title: "Payments",
		subtitle: null,
		icon: <CreditCard />,
		link: "/payments",
	},
	{
		id: "menu-settings",
		title: "Settings",
		subtitle: null,
		icon: <Settings />,
		link: "/settings",
	},
	{
		id: "menu-knowledge-base",
		title: "Knowledge Base",
		subtitle: null,
		icon: <GraduationCap />,
		link: "/knowledge-base",
	},
	{
		id: "menu-suggestions",
		title: "Suggestions",
		subtitle: null,
		icon: <Lightbulb />,
		link: "/suggestions",
	},
];
